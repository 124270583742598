<template>
  <section class="pd-20">
    <aPageHeader class="pd-0 mb-0">
      <template #title>
        <h1>
          <img
            src="@/assets/images/dashboard/credit-letter/list-ico.png"
            alt="img"
            width="30"
          />
          Cartas de Crédito
        </h1>
      </template>

      <template #extra>
        <downloadExcel
          class="btn btn-default"
          :header="excelFile.header"
          :name="excelFile.fileName"
          :data="creditLetter.list"
          :fields="excelFile.collumns"
          :footer="excelFile.footer"
          style="display: inline-block"
        >
          <img class="c-pointer" src="@/assets/images/dashboard/excel.png" />
        </downloadExcel>

        <aButton
          type="primary"
          ghost
          @click="onClickOpenCreateCreditLetterModal = true"
        >
          Cadastrar C. Crédito
        </aButton>
      </template>
    </aPageHeader>

    <aTabs
      v-if="creditCardStatusList.length"
      type="card"
      :default-active-key="activeTab"
      @change="onChangeTabs"
    >
      <aTabPane
        v-for="{ name } in creditCardStatusList"
        :key="name"
        :tab="name"
        force-render
      />
    </aTabs>

    <CreditLetterDataPanels :creditLetter="creditLetter" />

    <CreditLettersFilters
      :creditLetter="creditLetter"
      @onClickGetCreditLetters="onClickGetCreditLetters"
    />

    <aTable
      class="travel-table"
      :columns="creditLettersTableColumns"
      :data-source="creditLetter.list"
      :loading="creditLetter.loading"
      :pagination="false"
      :scroll="{ x: 1300 }"
      @change="creditLetterTableChange"
    >
      <div slot="customer" slot-scope="record">
        <div v-if="record?.customer?.id">
          <div
            class="dotted-phrase"
            style="width: 200px; display: block"
            v-if="record.customer?.person_type === 'Pessoa Física'"
          >
            {{ record.customer.id }} - {{ record.customer.first_name }}
            {{ record.customer.last_name }}
          </div>
          <div
            class="dotted-phrase"
            style="width: 100px; display: block"
            v-if="record.customer?.person_type === 'Pessoa Jurídica'"
          >
            {{ record.customer.id }} - {{ record.customer.trading_name }}
          </div>
        </div>
        <div v-else>---</div>
      </div>

      <div slot="value" slot-scope="record">
        {{ record.currency | formatCurrency }} {{ record.value | formatPrice }}
      </div>

      <div slot="available_value" slot-scope="record">
        {{ record.currency | formatCurrency }}
        {{ record.available_value | formatPrice }}
      </div>

      <div slot="spent_value" slot-scope="record">
        {{ record.currency | formatCurrency }}
        {{ record.spent_value | formatPrice }}
      </div>

      <div slot="expires_at" slot-scope="record">
        <div v-if="record.expires_at !== '0000-00-00'">
          {{ record.expires_at | formatDate }}
        </div>
        <div v-else>---</div>
      </div>

      <div slot="receipt_date" slot-scope="record">
        <div v-if="record.receipt_date !== '0000-00-00'">
          {{ record.receipt_date | formatDate }}
        </div>
        <div v-else>---</div>
      </div>

      <div slot="issuer" slot-scope="record">
        <div v-if="record.issuer.id">
          <a-tooltip>
            <template slot="title">
              {{ record.issuer.first_name }} {{ record.issuer.last_name }}
            </template>
            <span id="avatar">
              <a-avatar
                v-if="record.issuer.avatar"
                :src="record.issuer.avatar"
                :size="30"
              />
              <a-avatar
                v-else
                class="upper"
                style="color: #f56a00; background-color: #fde3cf"
              >
                {{ record.issuer.first_name.substring(0, 1)
                }}{{ record.issuer.last_name.substring(0, 1) }}
              </a-avatar>
            </span>
          </a-tooltip>
        </div>
        <div v-else><a-avatar icon="user" /></div>
      </div>

      <div slot="created" slot-scope="record">
        {{ record.created | formatDateTime }}
      </div>

      <div class="travel-actions" slot="action" slot-scope="record">
        <a class="edit ml-15" @click="onClickOpenCreditLetterModal(record.id)">
          <a-icon slot="prefix" :type="'eye-svg'" />
        </a>
      </div>
    </aTable>

    <div class="a-center mt-30">
      <a-pagination
        show-size-changer
        :default-current="creditLetter.pagination.page"
        :page-size.sync="creditLetter.pagination.perPage"
        :total="creditLetter.pagination.total"
        @change="changeCreditLetterPage"
        @showSizeChange="creditLetterChangePageSize"
      >
      </a-pagination>
    </div>

    <a-modal
      :width="1000"
      :footer="false"
      v-model="onClickOpenCreateCreditLetterModal"
      @cancel="onClickOpenCreateCreditLetterModal = false"
    >
      <template #title> CADASTRAR CARTA DE CRÉDITO </template>
      <CreditLetterForm
        v-if="onClickOpenCreateCreditLetterModal"
        :creditCardStatusList="creditCardStatusList"
        @onCreateCreditLetter="onCreateCreditLetter"
      />
    </a-modal>

    <a-modal
      class="miles-card-modal credit-letter-modal"
      :width="1000"
      :footer="false"
      v-model="openCreditLetterModal"
      @cancel="onCloseCreditLetterModal"
    >
      <CreditLettersModal
        :creditLetterId="creditLetterId"
        :creditCardStatusList="creditCardStatusList"
        v-if="openCreditLetterModal"
      />
    </a-modal>
  </section>
</template>

<script>
import reusableThings from "@/mixins/general/reusableThings";
import creditLetterReusables from "@/components/credit-letters/mixins/creditLetterReusables";
import CreditLettersModal from "@/components/credit-letters/modals/CreditLettersModal.vue";
import CreditLetterDataPanels from "@/components/credit-letters/sections/CreditLetterDataPanels.vue";
import CreditLettersFilters from "@/components/credit-letters/collapses/CreditLettersFilters.vue";
import CreditLetterForm from "@/components/credit-letters/forms/CreditLetterForm.vue";

export default {
  name: "CreditLettersList",
  components: {
    CreditLettersModal,
    CreditLetterDataPanels,
    CreditLettersFilters,
    CreditLetterForm,
  },
  mixins: [creditLetterReusables, reusableThings],
  data() {
    return {
      activeTab: "Ativo",
      openCreditLetterModal: false,
      onClickOpenCreateCreditLetterModal: false,
      creditLetterId: "",
      creditCardStatusList: [],
      excelFile: {
        header: "Cartas de Crédito",
        fileName: "Cartas de Crédito.xls",
        collumns: {
          ID: "id",
          Contrante: {
            field: "customer",
            callback: (customer) => {
              return `${customer.first_name} ${customer.last_name}`;
            },
          },
          Código: "code",
          Valor: {
            field: "value",
            callback: (value) => {
              return `R$ ${this.formatPrice(value)}`;
            },
          },
          Disponível: {
            field: "available_value",
            callback: (available_value) => {
              return `R$ ${this.formatPrice(available_value)}`;
            },
          },
          Utilizado: {
            field: "spent_value",
            callback: (spent_value) => {
              return `R$ ${this.formatPrice(spent_value)}`;
            },
          },
          Validade: {
            field: "expires_at",
            callback: (expires_at) => {
              return `${this.formatDate(expires_at)}`;
            },
          },
          Recebimento: {
            field: "receipt_date",
            callback: (receipt_date) => {
              return `${this.formatDate(receipt_date)}`;
            },
          },
          Emissor: {
            field: "issuer",
            callback: (issuer) => {
              return `${issuer.first_name} ${issuer.last_name}`;
            },
          },
          Cadastro: {
            field: "created",
            callback: (created) => {
              return `${this.formatDateTime(created)}`;
            },
          },
        },
        data: [],
        footer: [],
      },
    };
  },
  beforeCreate() {
    this.$http
      .get(
        `/category-entry/list?page=1&category_id=33&per_page=300&order-by=item_order&order=ascend`
      )
      .then(({ data }) => {
        this.creditCardStatusList = data.data;
      });
  },
  mounted() {
    this.creditLetter.filters.status.selected = [this.activeTab];
    this.getCreditLetters();
  },
  methods: {
    onChangeTabs(tab) {
      this.activeTab = tab;
      this.creditLetter.pagination.page = 1;
      this.creditLetter.filters.status.selected = [tab];
      this.getCreditLetters();
    },
    onCreateCreditLetter() {
      this.onClickOpenCreateCreditLetterModal = false;
      this.getCreditLetters();
    },
    onCloseCreditLetterModal() {
      this.openCreditLetterModal = false;
      this.creditLetterId = "";
      this.getCreditLetters();
    },
    onClickOpenCreditLetterModal(id) {
      this.creditLetterId = id;
      this.openCreditLetterModal = true;
    },
    onClickGetCreditLetters(filters) {
      console.log(filters);
      this.creditLetter.filters = filters;
      this.getCreditLetters();
    },
  },
};
</script>

<style lang="sass">
.credit-letter-modal
  .ant-modal-body
    min-height: 400px
</style>
