<template>
  <div>
    <a-row class="haya-panels mb-20 mt-5" :gutter="20">
      <a-col :span="3">
        <div class="box">
          <div class="title"></div>
          <div class="total">{{ creditLetter.meta.total }}</div>
          <div class="txt">Cartas de Crédito</div>
          <div class="footer">Total: {{ creditLetter.meta.total_general }}</div>
        </div>
      </a-col>

      <a-col v-if="creditLetter.meta.total_value != '0,00'" :span="6">
        <div class="box">
          <div class="title"></div>
          <div class="total cblack">R$ {{ creditLetter.meta.total_value }}</div>
          <div class="txt">Total Reais</div>
          <div class="footer">
            <a-row type="flex" justify="space-between">
              <a-col style="color: #ffbdbd">
                Utilizado: R$
                <b> {{ creditLetter.meta.total_spent }}</b>
              </a-col>

              <a-col style="color: #e4ffd9">
                Disponível: R$
                <b> {{ creditLetter.meta.total_available }}</b>
              </a-col>
            </a-row>
          </div>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
export default {
  name: "CreditLetterDataPanels",
  props: {
    creditLetter: Object,
  },
};
</script>
